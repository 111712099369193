var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"unavailableBookingSlotForm",attrs:{"id":"manage-unavailable-booking-slot-event-form"},on:{"submit":function($event){$event.preventDefault();return _vm.fire.apply(null, arguments)}}},[_c('v-container',{staticClass:"mb-16 pb-16",attrs:{"tag":"section","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-h6 pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans("blackout_date_form_employee_question"))+" ")]),(!_vm.allEmployees)?_c('v-col',{attrs:{"cols":"12"}},[_c('employee-field',{attrs:{"chips":"","deletable-chips":"","label":_vm.$trans('select_employee_label'),"rules":[_vm.rules.required],"disabled":_vm.eventUpdateMode ||
                !_vm.hasPermission(_vm.employeePermissions.SHOW_EMPLOYEES) ||
                _vm.disabledForm ||
                !!_vm.form.groupId},model:{value:(_vm.form.employee),callback:function ($$v) {_vm.$set(_vm.form, "employee", $$v)},expression:"form.employee"}})],1):_vm._e(),(
              !(_vm.eventUpdateMode && !_vm.allEmployees) &&
              _vm.hasPermission(_vm.employeePermissions.SHOW_EMPLOYEES)
            )?_c('v-col',{class:{ 'mt-0 pt-0': !_vm.allEmployees },attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","disabled":_vm.eventUpdateMode ||
                !_vm.hasPermission(_vm.employeePermissions.SHOW_EMPLOYEES) ||
                _vm.disabledForm ||
                !!_vm.form.groupId},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',[_vm._v(_vm._s(_vm.$trans("all_employees")))])])]}}],null,false,2709988035)},[_vm._v(" "+_vm._s(_vm.$trans("blackout_date_all_employees_warning"))+" ")])]},proxy:true}],null,false,232080617),model:{value:(_vm.allEmployees),callback:function ($$v) {_vm.allEmployees=$$v},expression:"allEmployees"}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{attrs:{"cols":"12"}},[_c('booking-form-date',{attrs:{"initial-wants-set-end-time":"","form":_vm.form,"label":_vm.$trans('blackout_date_form_date_question'),"hide-availability":"","disabled-form":_vm.disabledForm,"event-update-mode":_vm.eventUpdateMode},on:{"update":function($event){_vm.form = $event}}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"text-h6 mt-2 pb-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans("booking_form_additional_question"))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$trans('private_notes'),"outlined":"","rows":"3","placeholder":_vm.$trans('you_can_add_note_here'),"disabled":_vm.disabledForm,"rules":_vm.form.description ? [_vm.rules.maxChars(5000)] : [],"hide-details":"auto"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),(!_vm.eventUpdateMode || (_vm.eventUpdateMode && _vm.recurringBooking))?_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-0"},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"dense":"","label":_vm.$trans('recurring_booking'),"disabled":_vm.disabledForm || _vm.eventUpdateMode,"hide-details":""},model:{value:(_vm.recurringBooking),callback:function ($$v) {_vm.recurringBooking=$$v},expression:"recurringBooking"}})],1)],1):_vm._e(),(_vm.recurringBooking)?_c('v-row',[_c('v-col',{staticClass:"d-flex align-center align-content-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-no-wrap mr-2 v-label",class:{
                'checkbox-intro': _vm.$vuetify.breakpoint.mdAndUp,
              }},[_vm._v(" "+_vm._s(_vm.$trans("repeat_every"))+" ")]),_c('v-text-field',{staticClass:"small-width-input",attrs:{"rules":[
                _vm.rules.maxNumber(
                  _vm.recurringBookingRepeatEvery,
                  _vm.recurringBookingRepeatEveryMaxNumber
                ),
                _vm.rules.minNumber(_vm.recurringBookingRepeatEvery, 1) ],"outlined":"","hide-details":"auto","type":"number","dense":"","disabled":_vm.disabledForm},model:{value:(_vm.recurringBookingRepeatEvery),callback:function ($$v) {_vm.recurringBookingRepeatEvery=$$v},expression:"recurringBookingRepeatEvery"}}),_c('v-select',{staticClass:"ml-2",class:{
                'medium-width-input': _vm.$vuetify.breakpoint.mdAndUp,
              },attrs:{"hide-details":"auto","items":_vm.recurringBookingFrequencies,"outlined":"","rules":[_vm.rules.required],"dense":"","disabled":_vm.disabledForm},model:{value:(_vm.recurringBookingSelectedFrequency),callback:function ($$v) {_vm.recurringBookingSelectedFrequency=$$v},expression:"recurringBookingSelectedFrequency"}})],1)],1):_vm._e(),(_vm.recurringBooking)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center align-content-center",attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{attrs:{"dense":"","hide-details":"","disabled":_vm.disabledForm},model:{value:(_vm.recurringBookingEndsSelectedType),callback:function ($$v) {_vm.recurringBookingEndsSelectedType=$$v},expression:"recurringBookingEndsSelectedType"}},[_c('v-radio',{attrs:{"value":_vm.recurringBookingEndsSelectedTypes.OCCURRENCES},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-no-wrap mr-2 checkbox-intro"},[_vm._v(" "+_vm._s(_vm.$trans("ends_after_occurrences_1"))+" ")]),_c('v-text-field',{staticClass:"small-width-input",attrs:{"outlined":"","hide-details":"auto","disabled":_vm.disabledForm ||
                      _vm.recurringBookingEndsSelectedType ===
                        _vm.recurringBookingEndsSelectedTypes.DATE,"type":"number","dense":"","rules":[
                      _vm.rules.maxNumber(_vm.recurringBookingEndsAfter, 100),
                      _vm.rules.minNumber(_vm.recurringBookingEndsAfter, 1) ]},model:{value:(_vm.recurringBookingEndsAfter),callback:function ($$v) {_vm.recurringBookingEndsAfter=$$v},expression:"recurringBookingEndsAfter"}}),_c('div',{staticClass:"text-no-wrap ml-2"},[_vm._v(" "+_vm._s(_vm.$trans("ends_after_occurrences_2"))+" ")])]},proxy:true}],null,false,2832027432)}),_c('v-radio',{attrs:{"value":_vm.recurringBookingEndsSelectedTypes.DATE},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-no-wrap mr-2 checkbox-intro"},[_vm._v(" "+_vm._s(_vm.$trans("ends_at_date"))+" ")]),_c('calendar-input',{attrs:{"label":_vm.$trans('date'),"disabled":_vm.disabledForm ||
                      _vm.recurringBookingEndsSelectedType ===
                        _vm.recurringBookingEndsSelectedTypes.OCCURRENCES,"min":_vm.minDate,"max":_vm.maxDate,"dense":""},model:{value:(_vm.recurringBookingEndsAt),callback:function ($$v) {_vm.recurringBookingEndsAt=$$v},expression:"recurringBookingEndsAt"}})]},proxy:true}],null,false,3018610009)})],1)],1)],1):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('booking-form-date',{attrs:{"initial-wants-set-end-time":"","form":_vm.form,"label":_vm.$trans('blackout_date_form_date_question'),"hide-availability":"","disabled-form":_vm.disabledForm,"event-update-mode":_vm.eventUpdateMode},on:{"update":function($event){_vm.form = $event}}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }