<template>
  <v-form
    id="manage-unavailable-booking-slot-event-form"
    ref="unavailableBookingSlotForm"
    @submit.prevent="fire"
  >
    <v-container tag="section" fluid class="mb-16 pb-16">
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="text-h6 pb-0">
              {{ $trans("blackout_date_form_employee_question") }}
            </v-col>
            <v-col v-if="!allEmployees" cols="12">
              <employee-field
                v-model="form.employee"
                chips
                deletable-chips
                :label="$trans('select_employee_label')"
                :rules="[rules.required]"
                :disabled="
                  eventUpdateMode ||
                  !hasPermission(employeePermissions.SHOW_EMPLOYEES) ||
                  disabledForm ||
                  !!form.groupId
                "
              />
            </v-col>
            <v-col
              v-if="
                !(eventUpdateMode && !allEmployees) &&
                hasPermission(employeePermissions.SHOW_EMPLOYEES)
              "
              cols="12"
              :class="{ 'mt-0 pt-0': !allEmployees }"
            >
              <v-checkbox
                v-model="allEmployees"
                dense
                class="mt-0"
                hide-details
                :disabled="
                  eventUpdateMode ||
                  !hasPermission(employeePermissions.SHOW_EMPLOYEES) ||
                  disabledForm ||
                  !!form.groupId
                "
              >
                <template #label>
                  <v-tooltip right max-width="350">
                    <template #activator="{ on }">
                      <div v-on="on">
                        <span>{{ $trans("all_employees") }}</span>
                      </div>
                    </template>
                    {{ $trans("blackout_date_all_employees_warning") }}
                  </v-tooltip>
                </template>
              </v-checkbox>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12">
              <booking-form-date
                initial-wants-set-end-time
                :form="form"
                :label="$trans('blackout_date_form_date_question')"
                hide-availability
                :disabled-form="disabledForm"
                :event-update-mode="eventUpdateMode"
                @update="form = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-h6 mt-2 pb-0">
              {{ $trans("booking_form_additional_question") }}
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.description"
                :label="$trans('private_notes')"
                outlined
                rows="3"
                :placeholder="$trans('you_can_add_note_here')"
                :disabled="disabledForm"
                :rules="form.description ? [rules.maxChars(5000)] : []"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="!eventUpdateMode || (eventUpdateMode && recurringBooking)"
          >
            <v-col class="pt-0 mt-0">
              <v-checkbox
                v-model="recurringBooking"
                dense
                class="mt-0"
                :label="$trans('recurring_booking')"
                :disabled="disabledForm || eventUpdateMode"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row v-if="recurringBooking">
            <v-col class="d-flex align-center align-content-center" cols="12">
              <div
                class="text-no-wrap mr-2 v-label"
                :class="{
                  'checkbox-intro': $vuetify.breakpoint.mdAndUp,
                }"
              >
                {{ $trans("repeat_every") }}
              </div>
              <v-text-field
                v-model="recurringBookingRepeatEvery"
                :rules="[
                  rules.maxNumber(
                    recurringBookingRepeatEvery,
                    recurringBookingRepeatEveryMaxNumber,
                  ),
                  rules.minNumber(recurringBookingRepeatEvery, 1),
                ]"
                outlined
                hide-details="auto"
                type="number"
                dense
                class="small-width-input"
                :disabled="disabledForm"
              />
              <v-select
                v-model="recurringBookingSelectedFrequency"
                class="ml-2"
                :class="{
                  'medium-width-input': $vuetify.breakpoint.mdAndUp,
                }"
                hide-details="auto"
                :items="recurringBookingFrequencies"
                outlined
                :rules="[rules.required]"
                dense
                :disabled="disabledForm"
              />
            </v-col>
          </v-row>
          <v-row v-if="recurringBooking" no-gutters>
            <v-col
              class="d-flex align-center align-content-center"
              cols="12"
              md="6"
            >
              <v-radio-group
                v-model="recurringBookingEndsSelectedType"
                dense
                hide-details
                :disabled="disabledForm"
              >
                <v-radio :value="recurringBookingEndsSelectedTypes.OCCURRENCES">
                  <template #label>
                    <div class="text-no-wrap mr-2 checkbox-intro">
                      {{ $trans("ends_after_occurrences_1") }}
                    </div>
                    <v-text-field
                      v-model="recurringBookingEndsAfter"
                      outlined
                      hide-details="auto"
                      :disabled="
                        disabledForm ||
                        recurringBookingEndsSelectedType ===
                          recurringBookingEndsSelectedTypes.DATE
                      "
                      type="number"
                      dense
                      :rules="[
                        rules.maxNumber(recurringBookingEndsAfter, 100),
                        rules.minNumber(recurringBookingEndsAfter, 1),
                      ]"
                      class="small-width-input"
                    />
                    <div class="text-no-wrap ml-2">
                      {{ $trans("ends_after_occurrences_2") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="recurringBookingEndsSelectedTypes.DATE">
                  <template #label>
                    <div class="text-no-wrap mr-2 checkbox-intro">
                      {{ $trans("ends_at_date") }}
                    </div>
                    <calendar-input
                      v-model="recurringBookingEndsAt"
                      :label="$trans('date')"
                      :disabled="
                        disabledForm ||
                        recurringBookingEndsSelectedType ===
                          recurringBookingEndsSelectedTypes.OCCURRENCES
                      "
                      :min="minDate"
                      :max="maxDate"
                      dense
                    />
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6">
          <booking-form-date
            initial-wants-set-end-time
            :form="form"
            :label="$trans('blackout_date_form_date_question')"
            hide-availability
            :disabled-form="disabledForm"
            :event-update-mode="eventUpdateMode"
            @update="form = $event"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import event from "@/views/dashboard/pages/Calendar/components/ManageEvent/shared/event";
import EmployeeField from "@/views/dashboard/pages/Calendar/components/forms/Booking/EmployeeField.vue";
import { mapActions } from "vuex";
import BookingFormDate from "@/views/dashboard/pages/Calendar/components/ManageEvent/components/BookingFormDate.vue";
import CalendarInput from "@/lib/calendesk-js-library/components/CalendarInput.vue";

export default {
  name: "ManageUnavailableBookingSlotEvent",
  components: { CalendarInput, BookingFormDate, EmployeeField },
  mixins: [event],
  data() {
    return {
      allEmployees: false,
    };
  },
  computed: {
    eventUpdateMode() {
      return !!this.form.id;
    },
  },
  watch: {
    allEmployees(val) {
      if (val) {
        this.form.employee = null;
      }
    },
  },
  created() {
    this.reloadForm();
  },
  methods: {
    ...mapActions({
      createUnavailableBookingSpot: "booking/createUnavailableBookingSpot",
      updateUnavailableBookingSpot: "booking/updateUnavailableBookingSpot",
      refreshBookings: "booking/refreshBookings",
    }),
    reloadForm() {
      // Call the common parts in mixins
      if (
        this.$options.mixins[0] &&
        this.$options.mixins[0].methods.reloadForm
      ) {
        this.$options.mixins[0].methods.reloadForm.call(this);
      }

      this.allEmployees = !this.form.employee;
    },
    fire() {
      this.setManageEventButtonState(true);

      const action = !this.eventUpdateMode
        ? this.createUnavailableBookingSpot
        : this.updateUnavailableBookingSpot;

      if (this.$refs.unavailableBookingSlotForm.validate()) {
        if (this.recurringBooking) {
          this.form.recurrenceParams = `RRULE:FREQ=${this.recurringBookingSelectedFrequency};INTERVAL=${this.recurringBookingRepeatEvery};`;

          if (
            this.recurringBookingEndsSelectedType ===
            this.recurringBookingEndsSelectedTypes.OCCURRENCES
          ) {
            this.form.recurrenceParams += `COUNT=${this.recurringBookingEndsAfter}`;
          } else {
            this.form.recurrenceParams += `UNTIL=${this.$moment(
              this.recurringBookingEndsAt,
            )
              .endOf("day")
              .utc()
              .format("YYYYMMDDTHHmmss")}Z`;
          }
        }

        const eventActionName = "BlackoutDate";
        const eventActionType = this.eventUpdateMode ? "Update" : "Create";

        const data = this.$helpers.cloneObject(this.form);

        pushEvent(`eventForm${eventActionType}${eventActionName}`);

        if (this.form.groupId) {
          this.openConfirmDialog({
            type: confirmDialogTypes.EDIT_RECURRING_EVENT,
            data: {
              groupId: this.form.groupId,
              skipNotifications: false,
              isBlockingSpot: true,
              eventData: data,
            },
            close: (data) => {
              this.setManageEventButtonState(false);
              if (data && data.success) {
                this.$root.$emit("closeEventModal", true);
              }
            },
          });
        } else {
          action(data)
            .then(() => {
              this.refreshBookings();
              this.$root.$emit("closeEventModal", true);
            })
            .catch((error) => {
              if (this.recurringBooking) {
                errorNotification("recurring_booking_ends_info", error, false);
              }

              pushEvent(`eventFormError${eventActionType}${eventActionName}`, {
                error: error,
              });
            })
            .finally(() => {
              this.setManageEventButtonState(false);
            });
        }
      } else {
        errorNotification("form_is_invalid", {}, false);
        this.setManageEventButtonState(false);
      }
    },
  },
};
</script>
